import React from 'react'
import { Link } from 'react-router-dom'
//importing icons
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai'

import './footer.css';

let email = `mailto: kennmarcleaningservices@gmail.com`;

const Footer = () => {
    return(
        <div className="footer">
            <div className="footer-container">
                <div className="footer-content">
                    <div>
                        <p>Kennmar</p>
                        <ul>
                            <li><Link to="/about" className="footer-link"> About Us </Link></li>
                            <li><Link to="/#form-contact" className="footer-link"> Contact Us </Link></li>
                            <li><Link to="/" className="footer-link"> Our Mission </Link></li>
                        </ul>
                    </div>
                    <div>
                        <p>Service</p>
                        <ul>
                            <li><Link to="/services" className="footer-link"> Services </Link></li>
                            <li><Link to="/#form-contact" className="footer-link"> Contact Us </Link></li>
                            <li><Link to="/about" className="footer-link"> Our Vision </Link></li>
                        </ul>
                    </div>
                    <div>
                        <p className="footer-contact">Contact Us</p>
                        <ul>
                            <li><AiOutlinePhone />443-518-0049</li>
                            <li><AiOutlinePhone />301-580-6521</li>
                            <li className="footer-email"><AiOutlineMail /> kennmarcleaningservices@gmail.com </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <p className="footer-copyright">&#169; 2021 Kennmar Cleaning. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer;