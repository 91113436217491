import React from 'react'
import LazyLoad from 'react-lazyload'

const ServiceImage = ({ image, alt }) => {
    return(
        <LazyLoad>
            <img src={ image } alt={alt} />
        </LazyLoad>
    )
}

ServiceImage.defaultProps = {
    alt: 'Services provided at Kennmar'
}

export default ServiceImage;