export const moving = `
    Moving is a big job. In fact, many people say that moving is one of the most stressful and exhausting life experiences. Getting your things in order can 
    present a massive amount of work. On top of it all, you have to make sure the home you\'re leaving is clean and that your new home is ready for move-in day. 
    Let the experts at The KCS remove the burden of pre and post move cleaning from your to-do list. By nature, moving is messy. Our expert cleaning crews can make 
    sure that your new home or the one you\'re leaving looks spotless and sparkling with our move in and move out cleaning service.
`

export const oneTime = `
    Get the Deep Clean Your Home Needs. At KCS, we know the preferences of every homeowner will differ, which is why we proudly offer customizable cleaning 
    programs. No matter whether you need us to come by before a special event, spruce up your home before the holidays, or even clean before or after moving out, 
    we can help. With the use of our eco-friendly cleaning products, our trained cleaning professionals deep-clean your home from top to bottom.
`

export const greenHome = `
    Green cleaning, or cleaning with products made from environmentally friendly ingredients designed to preserve health and environmental quality, 
    s becoming increasingly important to homeowners across North America. Green or eco-friendly cleaning is more than just a trend; it signifies a 
    healthy and much-needed shift in thinking as more people choose to take steps necessary to protect themselves, their loved ones, and the environment. 
    \n We maintain our strong commitment to green cleaning by using only environmentally responsible or sustainable cleaning products. \n This is a 
    crucial part of our commitment to the environment and our customers. 
`

export const apartment = `
    Our customers keep coming back to us, because we provide services that work and keep their space clean, right down to the little details. This 
    extensive cleaning system, which we call our Detail-Clean Rotation System, has been proven to be effective in more than twenty million cleans!
`

export const house = `
    We use our unique Detail-Clean Rotation System to ensure that our customers' space always meets our high standard of cleanliness. This system was developed 
    over 25+ years of professional house cleaning experience. We use this system to ensure that our clients are always satisfied and can expect a consistently clean 
    and beautiful space.

` 
export const janitorial = `
    No matter how many employees you have, KCS can work with you to develop a program to address your daily, weekly and monthly professional cleaning needs. 
    Welcome guests with entrances and lobbies that have bright, glistening doors and windows, spotless surfaces, and a neat reception area. As soon as they 
    arrive, you will make a good first impression that will last all the way to the board room. With proper hard floor surface maintenance and daily carpet 
    care, we’ll make sure all of your high-traffic public areas like hallways and stairwells are safe and inviting. Offices, workstations, and conference 
    rooms are where most of each day's activity can be found and keeping them free of dirt and germs is essential to employee health and well-being. 
    From dusting to waste removal, KCS will keep your workplace spotless. Your employees will notice the attention to detail we give to every single desk, 
    cabinet, and chair. Our teams are trained to properly clean office system equipment from desktop items to large printers, which will keep you and your 
    team efficient and productive. In shared, commonly used areas such as break rooms and restrooms, keeping a clean, sanitary space is of the utmost importance. 
    To stop odors, maintain excellent indoor air quality, and make sure workplace health hazards steer clear, you'll find our sanitization services to be superior 
    and effective throughout the office building. 
`