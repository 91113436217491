import React from 'react'

const Services = ({ service, details }) => {
    return(
        <div>
            <h4>{ service }</h4>
            <p>{ details }</p>
        </div>
    )
}

export default Services;