import React from 'react'
import Services from '../../components/servicesPage/services'
import Table from '../../components/table/table'
import ServiceImage from '../../components/servicesPage/serviceImage'

import { moving, oneTime, greenHome, apartment, house, janitorial } from '../../components/servicesPage/service'

import wholeHouse from '../../assets/wholeHouse.png'
import apartmentImage from '../../assets/apartment.png'
import movingImage from '../../assets/moving.png'
import oneTimeImage from '../../assets/onetime.png'
import oneTimeImage1 from '../../assets/onetime.png'
import oneTimeImage2 from '../../assets/onetime2.png'
import janitorialImage1 from '../../assets/janitorial.png'
import janitorialImage2 from '../../assets/janitorial2.png'
import janitorialImage3 from '../../assets/janitorial3.png'

import './ServicesPage.css'

const serviceStyle = {
    marginTop: '10px',
}

const ServicesPages = () => {
    return(
        <div className="services">
            <h3>Services</h3>
            <Services service='Whole House Cleaning Services' details={ house }/>
            <ul>
                <li>
                    <b>First Clean: </b>We'll carefully detail-clean your kitchen and bathrooms, while providing a thorough cleaning to the rest of your house. 
                </li>
                <li>
                    <b>Second Clean: </b> We'll detail-clean your living areas and sleeping areas and provide a thorough clean to your kitchen and bathrooms as well. 
                </li>
            </ul>
            <p>
                After initial cleanings, our detail-clean services will be performed on a rotating basis along with our typical thorough cleaning services. This ensures 
                that your home is always up to par. We have high standards and our work speaks to that. We prove our commitment to our customers through maintaining our 
                high standards of excellence.
            </p>
            <ServiceImage image={ wholeHouse } />
            <Services service='Apartment Cleaning from Top to Bottom' details={ apartment }/>
            <Table />
            <ServiceImage image={ apartmentImage } />
            <Services service='Move in/ Move out Cleaning Services' details={ moving }/>
            <ServiceImage image={ movingImage } />
            <Services service='Green Home Cleaning' details={ greenHome }/>
            <Services service='One-Time Cleaning Servies' details={ oneTime }/>
            <ServiceImage image={ oneTimeImage1 } />
            <ServiceImage image={ oneTimeImage2 } />
            <Services service='Janitorial Cleaning Servies' details={ janitorial }/>
            <ServiceImage image={ janitorialImage1 } />
            <ServiceImage image={ janitorialImage2 } />
            <ServiceImage image={ janitorialImage3 } />
        </div>
    )
}

export default ServicesPages;