import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './navigation.css'

const style = {
    color: '#0049B6',
    fontWeight: '700'
}
const Navigation = () => {
    return(
        <Navbar sticky="top" bg="light" variant="light" className="justify-content-between navigation" expand="md">
            <Navbar.Brand href='/' style={ style }>Kennmar Cleaning</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navbar navbar-expand-lg">
                    <Nav.Link href='/'>Home</Nav.Link>
                    <Nav.Link href='/services'>Services</Nav.Link>
                    <Nav.Link href='/about'>About</Nav.Link>
                    <Nav.Link href='/#form-contact'>Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Navigation