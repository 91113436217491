import React from 'react'
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import wholeHouse from '../../assets/wholeHouse.png'
import apartmentImage from '../../assets/apartment.png'
import movingImage from '../../assets/moving.png'
import oneTimeImage from '../../assets/onetime.png'
import oneTimeImage1 from '../../assets/onetime.png'
import oneTimeImage2 from '../../assets/onetime2.png'
import janitorialImage1 from '../../assets/janitorial.png'
import janitorialImage2 from '../../assets/janitorial2.png'
import janitorialImage3 from '../../assets/janitorial3.png'

const style = {
    border: '1px solid #000',
    borderRadius: '50%',
    height: '10px',
    width: '10px',
    transition: '0.5s',

    "&:hover": {
        backgroundColor: '#000'
    }
}

const Gallery = () => {
    let setting = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div style={ style } className="dot"></div>
        )
    }
    return(
        <Slider {...setting}>
            <div>
                <img width="400" src={wholeHouse} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={apartmentImage} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={movingImage} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={oneTimeImage} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={oneTimeImage1} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={oneTimeImage2} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={janitorialImage1} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={janitorialImage2} alt='view our gallery' />
            </div>
            <div>
                <img width="400" src={janitorialImage3} alt='view our gallery' />
            </div>
        </Slider>
    )
}

export default Gallery;