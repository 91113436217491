import React from 'react'

const chooseUs = ({image, message}) => {
    return(
        <div className="choose">
            <div>
                <img src={ image } alt="choosing us at kennmar" />
            </div>
            <div>
                <h4>{ message }</h4>
            </div>
        </div>
    )
}

export default chooseUs;