import React from 'react'
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import before1 from '../../assets/before1.jpeg'
import before2 from '../../assets/before2.jpeg'
import after1 from '../../assets/after1.jpeg'
import after2 from '../../assets/after2.jpeg'

const style = {
    border: '1px solid #000',
    borderRadius: '50%',
    height: '10px',
    width: '10px',
    transition: '0.5s',

    "&: hover": {
        backgroundColor: '#fff'
    }
}

const Slides = () => {
    let setting = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        //appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div style={ style } className="dot"></div>
        )
    }
    return(
        <Slider {...setting}>
            <div>
                <img width="400" src={before1} alt='before cleaning crew at kennmar approach' />
            </div>
            <div>
                <img width="400" src={after1} alt='after cleaning crew at kennmar approach' />
            </div>
            <div>
                <img width="400" src={before2} alt='before cleaning crew at kennmar approach' />
            </div>
            <div>
                <img width="400" src={after2} alt='after cleaning crew at kennmar approach' />
            </div>
        </Slider>
    )
}

export default Slides;