import firebase from 'firebase/app';

import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyC3BGLs39f5B_xk49jwMC51oh4itTlsXIE",
    authDomain: "kennmar-cleaning.firebaseapp.com",
    projectId: "kennmar-cleaning",
    storageBucket: "kennmar-cleaning.appspot.com",
    messagingSenderId: "884728543940",
    appId: "1:884728543940:web:9e34bdfb70942305096791",
    measurementId: "G-XVC8CPQWBC"
};

firebase.initializeApp(firebaseConfig);

export let db = firebase.firestore();