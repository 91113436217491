import React from 'react'
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css';  

import Navigation from './components/navigation/navigation'
import Footer from './components/footer/footer'
import Landing from './Pages/Landing/Landing'
import Services from './Pages/ServicesPage/ServicesPage'
import About from './Pages/About/About'

import './App.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route exact path="/" component={Landing}/>
          <Route exact path="/services" component={Services}/>
          <Route exact path="/about" component={About} />
          <Route exact path="/" component={Landing}/>

          { /*404 redirect */ }
          <Redirect to='/' />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
