import React, { useState } from 'react'; //import useState to grab user responses

//importing database for firestore
import { db } from '../config/firebase';
import { contactUrl } from '../config/requests';


const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(''); //in the case an error comes up
    const [success, setSuccess] = useState(''); //when the user successfully fills out the form

    const sendEmail = async (name, email, number, company, message) => {
        const request = {
            method: 'POST',
            headers: { 'Content-Type' : 'application/json' },
            body: JSON.stringify({ name, email, number, company, message })
        }

        try{
            const response = await fetch(contactUrl, request)
            const data = await response.json();
            console.log(data)
        } catch(err){
            console.log(err)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault(); //preventing the default action of the form from occuring
        name.trim();
        email.trim();
        number.trim();

        if(name !== '' && email !== '' && message !== ''){
            sendEmail(name, email, number, company, message);

            try{
                db.collection('estimate').add(
                    {
                        name: name,
                        email: email,
                        number: number,
                        company: company,
                        message: message,
                        date: new Date()
                    }
                );
            } catch(err){
                console.log(err);
            }
            setName('');
            setEmail('');
            setNumber('');
            setCompany('');
            setMessage('');
            setError('');
            setSuccess('Thank you, we look forward to working with you!');
        } else{
            setError('Please Enter All Required Fields!');
        }
    }

    return(
        <div className="contact-container">
            <h3>Free Estimate</h3>
            <form onSubmit={ handleSubmit }>
                <div>
                    <input type="text" placeholder="Name*" value={name} onChange={(e) => setName(e.target.value)}/>
                    <input type="text" placeholder="Email*" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
                <div>
                    <input type="text" placeholder="Phone Number" value={number} onChange={(e) => setNumber(e.target.value)}/>
                    <input type="text" placeholder="Company Name" value={company} onChange={(e) => setCompany(e.target.value)}/>
                </div>
                <div>
                    <textarea placeholder="Message*" rows="4" cols="50" name="message" 
                        value={message} onChange={(e) => setMessage(e.target.value)}
                    />
                </div>
                <p className="error">{error}</p>
                <div className="submit">
                    <button type="submit">Submit</button>
                </div>
                <p className="success">{success}</p>
            </form>
        </div>
    )
}

export default Contact;