import React from 'react'
import LazyLoad from 'react-lazyload';

import kennmar1 from '../../assets/kennmar1.JPG'
import kennmar2 from '../../assets/kennmar2.JPG'
import ServiceImage from '../../components/servicesPage/serviceImage';
import './About.css'

const about = `
    Kennmar Cleaning Service (KCS) is a cleaning service and has a wide range of services to offer every client, from 
    Living area, Kitchen & Eating area, Sleeping Area, Bathrooms and any other place that needs our touch. Our experienced 
    cleaning team will bring all the tools and supplies needed to clean your space. Our amazing customer service team is 
    available through email or phone before, during, and after our service to make sure you are completely satisfied. If 
    you have any concerns, we promise to make things right. If for any reason you are unhappy with our service, with our 
    200% Satisfaction Guarantee, we promise to re-clean. We have excellent customer reviews. Our clients trust us and recommend 
    us to their friends and loved ones. We create relationships with our customers, generating loyalty and trust.
`

const vision = `
    Utilize our specialized Detail-Clean Rotation System. We use a proven system to ensure that our
    customers are happy with the result of our work. Our system has proven effective in over twenty
    million cleans. It is designed to be thorough and detail-oriented, and to emphasize quality.
`

const vision2 = `
    We take measures to ensure your confidence. Our company is insured and bonded, and all of our
    employees go through stringent screening and training for your peace of mind.
`

const About = () => {
    return(
        <div className="about">
            <h3>About Us</h3>
            <p> { about } </p>
            <h4><b>Our Vision</b></h4>
            <p>{ vision }</p>
            <p>{ vision2 }</p>
            <div className="intro">
                <ServiceImage image={ kennmar1 } alt="employee at kennmar"/>
                <ServiceImage image={ kennmar2 } alt="employee at kennmar"/>
            </div>
        </div>
    )
}

export default About;