import React from 'react'

const Table = () => {
    return(
        <table>
            <thead>
                <tr>
                    <th>Bathroom</th>
                    <th>Sleeping Area</th>
                    <th>Kitchen and Dining</th>
                    <th>Living Area</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Clean Tile walls, bathtubs and showers</td>
                    <td>Hand wipe flat surfaces</td>
                    <td>Clean exterior and interior of appliances <span>(Oven, Fridge, Stove Top, Microwave, and Dishwasher)</span></td>
                    <td>Dust TV and accessories</td>
                </tr>
                <tr>
                    <td>Clean shower doors</td>
                    <td>Clean doors and dorr frames</td>
                    <td>Clean countertops and sanitize</td>
                    <td>Wipe couches and furniture</td>
                </tr>
                <tr>
                    <td>Clean vanity and sink</td>
                    <td>Remove cobwebs</td>
                    <td></td>
                    <td>Clean hallways, staircases and table tops</td>
                </tr>
                <tr>
                    <td>Clean and shine mirrors and chrome fixtures</td>
                    <td>Replace sheets (if provided) and make bed</td>
                    <td>Vacuum floors and damp</td>
                    <td>Wipe shelves, tables and chairs</td>
                </tr>
                <tr>
                    <td>Vacuum and clean floors and carpets</td>
                    <td>Wipe down and clean mirror</td>
                    <td>Dust windows, ledges and blinds</td>
                    <td>Vacuum and mop floors</td>
                </tr>
                <tr>
                    <td>Clean and sanitize toilet thoroughly</td>
                    <td>Dust all accessible surfaces</td>
                    <td>Clean doors and door frames</td>
                    <td>Dust window sills and blinds</td>
                </tr>
                <tr>
                    <td>Dust window sills, ledges and blinds</td>
                    <td>Vacuum wood floor and damp mop</td>
                    <td>General dusting</td>
                    <td>Dust accessible vents</td>
                </tr>
                <tr>
                    <td>Remove cobwebs</td>
                    <td>Vacuum stairs</td>
                    <td></td>
                    <td>Dust picture frames</td>
                </tr>
                <tr>
                    <td>Clean doors and door frames</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    )
}

export default Table;