import React from 'react'

import { Link } from 'react-router-dom'

import Choose from '../../components/chooseUs/chooseUs'
import Contact from '../../components/contact/contact'

import Slides from '../../components/sliders/sliders'
import Gallery from '../../components/sliders/gallery'

import clean from '../../assets/landingImage.svg'
import money from '../../assets/money.svg'
import broom from '../../assets/broom.svg'
import control from '../../assets/control.svg'
import bell from '../../assets/bell.svg'
import smile from '../../assets/smile.svg'


import './Landing.css'
const Landing = () => {
    return(
        <div className="landing">
            <div className="section1">
                <div className="kennmar">
                    <h1>KENNMAR</h1>
                    <h3>CLEANING SERVICES</h3>
                    <p>Let us clean your dirt and keep it shining</p>
                    <div className="buttons">
                        <Link className="clean" to='/services'>Cleaning Services</Link>
                        <a href="#form-contact" className="contact">Contact Us</a>
                    </div>
                </div>
                <div>
                    <img src={clean} alt="kennmar cleaning" />
                </div>
            </div>
            <div className="section2" id="section2">
                <div className="container">
                    <h3>Our Mission</h3>
                    <p>
                        Provide customers with quality cleaning services in an environmentally sound, completely
                        trustworthy, and professional manner. We exist to attract and maintain customers. Our services
                        must exceed the expectations and satisfaction of our customers.
                    </p>
                </div>
            </div>
            <div className="section3">
                <div className="container">
                    <h3>Why Choose Us</h3>
                    <div className="why">
                        <Choose image={broom} message="Professional Cleaning" />
                        <Choose image={broom} message="Commercial " />
                        <Choose image={bell} message="Quality Service" />
                    </div>
                    <div className="why">
                        <Choose image={money} message="Affordable Pricing" />
                        <Choose image={control} message="Quality Control" />
                        <Choose image={smile} message="Satisfaction Guarantee" />
                    </div>
                    <div className="before-after">
                        <h3>Before and After</h3>
                        <div className="slides">
                            <Slides />
                        </div>
                    </div>
                    <div className="before-after">
                        <h3>Our Gallery</h3>
                        <div className="slides">
                            <Gallery />
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="section4" id="form-contact">
                <Contact />
            </div>
        </div>
    )
}

export default Landing;